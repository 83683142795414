import { useQuery } from '@tanstack/vue-query'
import type { Branding } from '~/types/branding'

export const useStoreBranding = defineStore('branding', () => {
  const brandings = ref<Branding | null>(null)

  const fetchBrandings = (companyId: number) => {
    return useQuery({
      queryKey: ['branding', companyId],
      queryFn: async () => {
        const branding = (await useNuxtApp().$api(`/api/creator/company/${companyId}/brandings/`)) as Branding
        brandings.value = branding
        return branding
      },
      staleTime: 6400000 // 2 hours
    })
  }

  return { brandings, fetchBrandings }
})
